import React from 'react';
import './style.css';

import Main from "./Main"

import Login from "./Main";

export default function App() {
  return (
    <div>
      

       <Main/>

    </div>
  );
}
